import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import SignUpForm from '../components/Forms/SignUpForm';

const IndexPage = () => (
  <Layout>
    <SEO
      title='Home'
      keywords={[`fathering.me`, `fatherlessness`, `new father`, `unplanned`]}
    />
    <SignUpForm />
  </Layout>
);

export default IndexPage;
